<template>
  <div class="flex w-full flex-col justify-start items-center bg-white main">
    <section class="h-full pt-32 container-width">
      <div v-html="parseMarkdown()"></div>
    </section>
  </div>
</template>

<script>
import marked from "marked";
import axios from "axios";

export default {
  methods:{
    parseMarkdown() {
  return marked(this.content);
},
},
data() {
  return {
    content: "",
    loading: true,
    errored: false
  };
},
mounted() {
  axios
    .get("https://raw.githubusercontent.com/macdesket/zacky/main/jobs.json")
    .then(response => {
      this.content = response.data.find(x=> x.link === this.$route.params.job).content;
      this.loading = false;
    })
    .catch(error => {
      this.errored = error;
    });
  }
};
</script>

<style lang="css" scoped>
a {
  background-color: transparent;
}
img {
  border-style: none;
}
*,
:after,
:before {
  box-sizing: inherit;
}
ul {
  margin: 0;
}
*,
:after,
:before {
  border: 0 solid rgba(2, 25, 38, 0.07);
}
img {
  border-style: solid;
}
img {
  max-width: 100%;
  height: auto;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.h-full {
  height: 100%;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.ml-12 {
  margin-left: 3rem;
}
.pt-32 {
  padding-top: 8rem;
}
.text-3xl {
  font-size: 1.875rem;
}
.w-full {
  width: 100%;
}
* {
  font-family: Poppins, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #0b2438;
  font-display: swap;
}
body a:not(.navbar-link) {
  color: #ff6b6b;
}
body a:not(.navbar-link):active,
body a:not(.navbar-link):hover,
body a:not(.navbar-link):visited {
  color: #ff5064;
}
body a {
  text-decoration: none;
}
body li {
  line-height: 2;
}
body li + li {
  margin-top: 10px;
}
body :not(li) > ul {
  list-style: none;
  padding: 0;
  position: relative;
  margin-left: 15px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
body :not(li) > ul > li {
  padding-left: 25px;
}
body :not(li) > ul > li:before {
  content: "";
  position: absolute;
  left: 0;
  vertical-align: middle;
  font-weight: 400;
  color: #0b2438;
  font-size: 10px;
  margin-top: 4px;
}
/*! CSS Used from: Embedded */
div,
a {
  user-select: text !important;
}
::selection {
  background-color: #338fff !important;
  color: #fff !important;
}
.container-width {
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
@media (min-width: 992px) {
  .container-width {
    width: 40rem;
  }
}
li {
  list-style: disc;
  padding-left: 0;
}
</style>
